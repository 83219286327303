import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import './WhatsAppFloatIcon.css';

const WhatsAppFloatIcon = () => {
    const whatsappNumber = '+18328851028';

    return (
        <a href={`https://wa.me/${whatsappNumber}`} className="whatsapp-floating-icon-link" target="_blank" rel="noopener noreferrer" >
            <WhatsAppIcon className="whatsapp-floating-icon"/>
        </a>
    );
};

export default WhatsAppFloatIcon;