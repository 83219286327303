import React from 'react';
import './contact.css';
import { useState, useContext} from "react";
import { langajes } from '../_helpers/languajes';
import { langContext } from '../App';
import { categoryService } from '../_services/category.services';
import Header from './commons/Header';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


export const Contact = () => {
const [mail, setMail] = useState("");
const [nombre, setNombre] = useState("");
const [tel, setTel] = useState("");
const [subject, setSubject]= useState("");
const [message, setMessage] = useState("");
const [checkState, setCheckstate] = useState(false);
const [sendOK, setSendOK] = useState(false);
const [open, setOpen] = useState(false);
const l = useContext(langContext);


const idioma = ()=>{
    if(l.lang==='es'){
       return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
}

const handleChangeCheck = (e) => {
   setCheckstate(e.target.checked);
}

const handleSendMessage = async(e) =>{
    setOpen(true);
    e.preventDefault();
    try {
        const response = await categoryService.sendeMailcontact(nombre,mail,tel,subject,message,checkState,l.lang);
        setSendOK(true);
        setOpen(false);
    }catch (error){
        console.log(error);
        setOpen(false);
    }

}

  return (
    <div className='row'>
        <Header/>
        <div className='col-12'>
            <img className='imgContact' src="contactBanner.png" alt={""}/>
        </div>
        <div className='col-12'>
            <div className='row'>
                <div className='col-sm-12 col-lg-6 colText'>
                    <div className='row '>
                        <div className='col-12 '>
                            <div className='row rowTit'>
                                <h1>{idioma().contacto.contactoTitulo}</h1>
                            </div>
                            <h3 className='subTit'>Houston</h3>
                            <p>7601 North Loop,</p>
                            <p>Houston, TX 77028</p>
                            <p>Tel: (832) 885-1028</p>
                            <p>hola@productosbredy.com</p>
                        </div>
                        <div className='col-12 colText txtHorario'>
                            <h3 className='saubTit'> {idioma().footer.horario}</h3>
                            <p>{idioma().footer.dias}</p>
                            <p>8am - 6pm</p>
                            <p>{idioma().footer.findsem} 9am - 2pm</p>
                        </div>
                        
                    </div>
                </div>
                <div className='formCol col-sm-12 col-lg-6 justify-content-center'>
                    <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                        >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {sendOK === true ? (
                        <h1>{idioma().contacto.sendResp}</h1>
                    ):(
                        <form className='formSt'  onSubmit={handleSendMessage}>
                        <label>{idioma().unete.formulario1}</label>
                        <input id="nombre" type="text" required
                            onChange={e => setNombre(e.target.value)}
                        />
                        <label>Email:*</label>
                        <input id="email" type='email' required
                            onChange={e => setMail(e.target.value)}
                        />
                        <label>{idioma().unete.formulario2}</label>
                        <input id='email' type='tel' required
                            onChange={e => setTel(e.target.value)}
                        />
                        <label>{idioma().contacto.formularioAs}</label>
                        <input id="asunto" type='text' required
                            onChange={e => setSubject(e.target.value)}
                        />
                        <label>{idioma().unete.formulario3}</label>
                        <textarea id='mensaje' required
                            onChange={e => setMessage(e.target.value)}
                        />
                        <label className='labCheck'>
                          <input 
                            required
                            onChange={handleChangeCheck}
                            type='checkbox' name='politica' /><p className='pPol'>{idioma().contacto.checkPol} <a className='alinkPol'>{idioma().contacto.checklink}</a></p>
                        </label>
                        <div className='btnContainer'>
                            <button className='linkSendMail'  >{idioma().unete.boton}</button>
                        </div>
                       
                    </form>
                    )}
                    
                </div>
            </div>
            <div className='row'>
                <div className='col-12 ventaTiendas'>
                    <h3>{idioma().contacto.suctitulo3}</h3>
                    <p></p>
                </div>
                <div className='col-6 col-md-4 colSucs'>
                    <h3>AUSTIN</h3>
                    <p>7601 North Loop,<br/>Houston, TX 77028<br/>Tel: (512) 817-7538</p>
                </div>
                <div className='col-6 col-md-4 colSucs'>
                    <h3>LOUSIANA</h3>
                    <p>7601 North Loop,<br/>Houston, TX 77028<br/>Tel: (504) 345-6539</p>
                </div>
                <div className='col-6 col-md-4 colSucs'>
                    <h3>ARKANSAS</h3>
                    <p>7601 North Loop,<br/>Houston, TX 77028<br/>Tel: (713) 820-5030</p>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 colDistArt'>
                        <h3>{idioma().contacto.suctitulo1}</h3>
                        <p>7601 North Loop,<br/>Houston, TX 77028<br/>Tel: (832) 407-9439</p>
                    </div>
                    <div className='col-12 col-md-6 colDistArt'>
                        <h3>{idioma().contacto.suctitulo2}</h3>
                        <p>1216 E 27th St,<br/>Houston, TX 77009<br/>Tel: (832) 407-9439</p>
                    </div>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
           
                <img className='logoContactUs' src='logoBredy.png' alt={""}/>
            </div>
            <div className="row">
                <div className="follow col-12">
                    <p className="followText">{idioma().footer.redes} </p>
                    <a target="_blank" href="https://www.facebook.com/BredyDistributors"><img src="facebook.png" alt={""}/></a>
                    <a target="_blank" href="https://www.instagram.com/bredydistributors/?hl=en"><img src="IG.png" alt={""}/></a>
                    <a target="_blank" href="https://www.linkedin.com/company/productos-bredy-usa-llc/about/"><img src="linkedin.png" alt={""}/></a>
                    <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B18328851028&text&type=phone_number&app_absent=0"><img src="whats.png" alt={""}/></a>
                </div>
            </div>
            <div className="brandBredy">
                <p>Productos Bredy USA, LLC. @2024</p>
            </div>
        </div>

    </div>
  )
}
