import React from "react";

const Videocomponent = (props) => {
    return (
            <video width="100%" height="auto"  loop muted autoPlay controls = ''>
                <source src={props.urlVideo} type="video/mp4"/>
            </video>
    );
}

export default Videocomponent;