import React, { useEffect, useState } from 'react';
import Footer from './commons/Footer';
import './flyer.css';
import { useContext} from "react";
import { langajes } from '../_helpers/languajes';
import { langContext } from '../App';
import Header from './commons/Header';
import { getLatestFlyer } from "../_services/flyer.services";

export const Flyer = () => {
  const [flyerData, setFlyerData] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  const l = useContext(langContext);

  const idioma = ()=> {
      if(l.lang==='es'){
         return langajes.spanish
      } else if (l.lang==='en'){
          return langajes.english
      }
  }

  useEffect( () => {
    const fetchLatestFlyer = async () => {
      try {
        const data = await getLatestFlyer()
        setFlyerData(data.data)
      } catch (error) {
        console.log(error)
        setMessage("No hay información reciente...")
      }
    }

    fetchLatestFlyer().finally(onFinally => { setLoading(false) } );
  }, [] );

  return (
    <div>
      <Header/>
      <div className='flyerContainer'>
        <div className='row'>
            <h1 className='titleFlyer'>{idioma().flyer.tituloFyer}</h1>
        </div>
        <div className='row containerFlayer'>
          { loading ? (
            <div className="loading-overlay">
              <div className="spinner"></div>
            </div>
            ) : (
              flyerData ? (
                <iframe title="Catalogo de productos" allowFullScreen="allowfullscreen" scrolling="no" className="fp-iframe flayerIframe" width={ "100%" } height={ "600px" } src={ flyerData.url }></iframe>
              ) : (
                <p className={'text-dark'}>{ message }</p>
              )
            )
          }
        </div>
      </div>
      <Footer/>
    </div>
  )
}