import React, {useContext, useEffect} from 'react';
import { HeaderAd } from './HeaderAd';
import '../components/admin.css';
import {useNavigate} from 'react-router-dom'
import {langContext} from "../App";

export const Admin = () => {
const navigate = useNavigate();
const t =useContext(langContext);

const handleTitleCatAd = () =>{
    t.setTitPlaceAd('Administrador');
}
useEffect(() => {
    handleTitleCatAd();
},[]);

const handleCatAd = () =>{
  navigate('/admin_categorias');
}

const handleProdAd = () => {
   // navigate('/admin_productos');
    window.location.href='/admin_productos';
}

const handleFlyerAd = () => {
  navigate('/admin_flyer_home');
}

  return (
    <div className='adminCont'>
        <HeaderAd/>
        <div className='row adminHome'>
          <div className='contAdminPC'>
            <div onClick={ handleProdAd } className='linkAdPC'><img src="prodsAd.png" alt={ "" }/></div>
            <div onClick={ handleCatAd } className='linkAdPC'><img src='catsAd.png' alt={ "" }/></div>
            <div onClick={ handleFlyerAd } className='linkAdPC'><img src='flyerAd.png' alt={ "" }/></div>
          </div>
        </div>
    </div>
  )
}
