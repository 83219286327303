import React from "react";
import './delivery.css';
import {useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';

const Delivery = () =>{
    const l = useContext(langContext);

    const idioma = ()=>{
        if(l.lang==='es'){
           return langajes.spanish
        }else if(l.lang==='en'){
            return langajes.english
        }
    }

    return (
        <div className="row divContainer" >
            <div className="d-none d-sm-block deliveryImageContainer col-12">
                <img className="imagePadding" src={idioma().delivery.urlImgDesk} alt={""}/>
            </div>
            <div className="col-12 justify-content-center  d-xs-block d-sm-none d-md-none deliveryImageContainer">
                <div className="row imgMobDel">
                    <img className="imagePadding" src={idioma().delivery.imgeMob1} alt={""}/>
                </div>
                <div className="row imgMobDel">
                    <img className="imagePadding" src={idioma().delivery.imgeMob2} alt={""}/>
                </div>
                <div className="row imgMobDel">
                     <img className="imagePadding" src={idioma().delivery.imgeMob3} alt={""}/>
                </div>
            </div>
        </div>
    );
};

export default Delivery;

