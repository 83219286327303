import React, {useEffect, useState, useContext} from 'react'
import { HeaderAd } from './HeaderAd'
import { categoryService } from '../_services/category.services'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import './admnCategorias.css';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { langContext } from '../App';
import {useNavigate} from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { IOSSwitch } from "./commons/StyledComponents";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: 0,
  minWidth: '1100px',
  paddingBottom: '50px'
};

export const AdmnCategorias = () => {
const [catList, setCatList] = useState([]);
const token = JSON.parse(localStorage.getItem('access_token'));
const [open, setOpen] = useState(false);
const [openM, setOpenM] = useState(false);
const t = useContext(langContext);
const navigate = useNavigate();
const [order, setOrder]= useState();
const [spanish, setSpanish] = useState();
const [english, setEnglish] = useState();
const [status, setStatus] = useState();
const [id,setId] = useState();
const [newEsp, setNewesp] = useState();
const [newEng, setNewEng] = useState();
const [expanded, setExpanded] = React.useState(false);
const [alertMessage, setAlertMessage] = useState();
const [severity, setSeverity] = useState();
const [openAdd, setOpenAdd] = useState(false);

  const tokenRev = (error) => {
    if(error === "Unauthenticated."){
      navigate('/login');
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
    }else{
    }
  }

const getCatList = async (token) =>{
  setOpen(true);
  try{
    const response = await categoryService.categoryList(token);
    setCatList(response.data);
    console.log(response.data)
    setOpen(false);
  }catch(error){
    console.log(error);
    tokenRev(error);
  }
}

const handleTitleCatAd = () =>{
  t.setTitPlaceAd('Categorías');
}

useEffect(() => {
  handleTitleCatAd();
  getCatList(token);
},[])

const handleDeactivCat = async (e, orderd, spanishd, englishd, status,idd) =>{
  setOpen(true)
  e.preventDefault();
  try{
    if(status === 1){
      let nStatus = 0
      await categoryService.updateCat(token, orderd, spanishd, englishd, nStatus, idd);
      setAlertMessage('Categoría deshabilitada');
      setSeverity('warning')
      setExpanded(true);
    setTimeout(()=>{
        setExpanded(false);
    },3000);
      getCatList(token);
      setOpen(false);
    }
    if(status === 0){
      let nStatus = 1
      await categoryService.updateCat(token, orderd, spanishd, englishd, nStatus, idd);
      setAlertMessage('Categoría habilitada con éxito');
      setSeverity('success')
      setExpanded(true);
      setTimeout(()=>{
          setExpanded(false);
      },3000);
      getCatList(token);
      setOpen(false);
    }
  }catch(error){
      console.log(error);
      setOpen(false);
      tokenRev(error);
    }
}
  

const handleOpen = (e,orderl,spanishl, englishl, statusl,idl) => {
  e.preventDefault();
  setOpenM(true);
  setOrder(orderl);
  setSpanish(spanishl);
  setEnglish(englishl);
  setStatus(statusl);
  setId(idl);  
};
const handleChangeInputEdit = (e) =>{
  e.preventDefault()
  if(e.target.name === 'nameEsp'){
    setNewesp(e.target.value);
  }
  if(e.target.name === 'nameEng'){
    setNewEng(e.target.value);
  }
}

const handleClose = () => {
  setOpenM(false);
  setOpenAdd(false);
  setNewEng();
  setNewesp();
};

const handleUpdateCat = async (e) =>{
  e.preventDefault();
  setOpen(true);
  setOpenM(false);
  try{
    const response = await categoryService.updateCat(token, order, newEsp, newEng, status, id);
    console.log(response);
    handleClose();
    setAlertMessage('Categoría actualizada con éxito');
    setSeverity('success')
    setExpanded(true);
    setTimeout(()=>{
        setExpanded(false);
    },3000);
    getCatList(token);
  }catch(error){
    handleClose();
    console.log(error);
    setSeverity(error);
    setAlertMessage(error);
    setExpanded(true);
    setTimeout(()=>{
        setExpanded(false);
    },3000);
    tokenRev(error);
  }
  
}

const handleOpenAdd = () =>{
  setOpenAdd(true);
}

const handleCreateCat = async (e) => {
    e.preventDefault();
    setOpen(true);
    try{
      const response = await categoryService.addCategory(token,newEsp,newEng);
      console.log(response);
      handleClose();
      setAlertMessage('Categoría creada con éxito');
      setSeverity('success')
      setExpanded(true);
      setTimeout(()=>{
          setExpanded(false);
      },3000);
      getCatList(token);
    }catch(error){
      console.log(error);
      handleClose();
      setSeverity(error);
      setAlertMessage(error);
      setExpanded(true);
      setTimeout(()=>{
          setExpanded(false);
      },3000);
      tokenRev(error);
    }
}
  const handleRegresar = () => {
    navigate('/admin');
  }

  return (
    <div>
        <HeaderAd/>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Alert severity={severity}>{alertMessage} </Alert>
        </Collapse>
      <div className='row contGralCats'>
        <div className='col-12'>
          <Button
              onClick={handleRegresar}
              sx={{border: '1px solid black', borderRadius: '15px', color: '#000'}} variant="outlined"
              startIcon={<ArrowBackIosNewIcon/>}>
            Regresar
          </Button>
        </div>
        <div className='colLis'>
          <div className='divCatList'>
            <List>
              <ListItem sx={{paddingLeft: 0}}>
                <Button onClick={handleOpenAdd} sx={{border: '1px solid black', borderRadius: '15px', color: '#000'}}
                        variant="outlined" endIcon={<AddIcon sx={{color: '#20c997'}}/>}>
                  Agregar Nuevo
                </Button>
              </ListItem>
              {catList.map((cat) => (
                  <ListItem className='itemCats' sx={{maxHeight: '50px'}} key={cat.id}>
                    <ListItemIcon sx={{width: 'fit-content'}}
                                  onClick={(e) => handleOpen(e, cat.order, cat.spanish, cat.english, cat.status, cat.id)}>
                      <EditOutlinedIcon sx={{color: '#5fB4F9'}}/>
                    </ListItemIcon>
                    <ListItemText primary={cat.spanish}/>
                    <FormGroup>
                      <FormControlLabel
                          label=''
                          checked={ cat.status === 1 }
                          control={<IOSSwitch sx={{m: 1}}/>}
                          onClick={(e) => handleDeactivCat(e, cat.order, cat.spanish, cat.english, cat.status, cat.id)}
                      />
                    </FormGroup>
                  </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
      <Modal
          open={openM}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className='row'>
            <h1 className='titModalEdit'>Editar Categoría:</h1>
          </div>
          <div className='row rowContEdit'>
              <div className='col-5 d-flex justify-content-center'>
                <h3 className='titCatEdit'>{spanish}</h3>
              </div>
              <div className='col-7 colContForm'>
                  <div className='catEditTitSts'>
                  {status === 1 ? (
                    <h3 className='titCatEditAct'>Activo</h3>
                  ):(
                    <h3 className='titCatEditInAct'>Inactivo</h3>
                  )}
                </div>
                <form className='divInputsEdit' onSubmit={e =>handleUpdateCat(e)}>
                  <label className='labelEdit'>Nombre actual en español: {spanish}</label>
                  <input name="nameEsp" onChange={(e)=>handleChangeInputEdit(e)} required
                  type='text' placeholder='Nuevo nombre' className='inputEdit'/>
                  <label className='labelEdit'>Nombre actual en inglés: {english}</label>
                  <input name="nameEng" onChange={(e)=>handleChangeInputEdit(e)} required
                  type='text' placeholder='New name' className='inputEdit'/>
                  <div className='divBtnEdit'>
                    <button type='submit'
                    className='btnEdit'>Guardar</button>
                  </div>
                 
                </form>
            </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openAdd}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className='row'>
              <h1 className='titModalEdit'>Agregar Categoría</h1>
            </div>
            <div className='row rowContEdit'>
              <div className='col-5 d-flex justify-content-center'>
                <h3 className='titCatEdit'>Nuevo</h3>
              </div>
              <div className='col-7 colContForm'>
                  <div className='catEditTitSts'>
                  {status === 1 ? (
                    <h3 className='titCatEditAct'>Activo</h3>
                  ):(
                    <h3 className='titCatEditInAct'>Inactivo</h3>
                  )}
                </div>
                <form className='divInputsEdit' onSubmit={e =>handleCreateCat(e)}>
                  <label className='labelEdit'>Nombre en español:</label>
                  <input name="nameEsp" onChange={(e)=>handleChangeInputEdit(e)} required
                  type='text' placeholder='Nuevo nombre' className='inputEdit'/>
                  <label className='labelEdit'>Nombre en inglés:</label>
                  <input name="nameEng" onChange={(e)=>handleChangeInputEdit(e)} required
                  type='text' placeholder='New name' className='inputEdit'/>
                  <div className='divBtnEdit'>
                    <button type='submit'
                    className='btnEdit'>Guardar</button>
                  </div>
                 
                </form>
            </div>
            </div>
          </Box>
        </Modal>
    </div>
  )
}
