import React from 'react';
import {Routes, Route, BrowserRouter,Navigate} from 'react-router-dom';
import { Home } from '../components/Home';
import { Contact } from '../components/Contact';
import { Unete } from '../components/Unete';
import { Products } from '../components/Products';
import { Flyer} from '../components/Flyer';
import { Login } from '../components/Login';
import { Admin } from '../components/Admin';
import { AdmnCategorias} from '../components/AdmnCategorias'
import { AdminFlyerHome } from "../components/admin/Flyer/AdminFlyerHome";
import { AdminProductsHome } from "../components/admin/Product/AdminProductsHome";

export const RouterPrincipal = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/home' element={<Home/>} />
            <Route path='/inicio' element={<Home/>} />
            <Route path='/contacto' element={<Contact/>} />
            <Route path='/flyer' element={<Flyer/>} /> 
            <Route path='/unete' element={<Unete/>} />
            <Route path='/productos' element={<Products/>} />
            <Route path='/login' element={<Login/>}/>
            <Route path='/admin' element={<Admin/>}></Route>
            <Route path='/admin_categorias' element={<AdmnCategorias/>}></Route>
            <Route path='/admin_productos' element={<AdminProductsHome/>}></Route>
            <Route path='/admin_flyer_home' element={<AdminFlyerHome/>}></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
       
    </BrowserRouter>
  )
}
