const API_URL = process.env.REACT_APP_API;

export const categoryService ={
    getCategories,
    getProducts,
    sendEmailCarreer,
    sendeMailcontact,
    loginAdmin,
    categoryList,
    updateCat,
    addCategory,
    adminProductList,
    updateProduct,
    updateProductImg,
    createProduct,
    logOut,
};

function getCategories (lang){
    const requestOptions = {
        method: "POST",
        headers: {
            "Accept-Language": lang
        }
    };
    return fetch(
`${API_URL}/api/categories`,
        requestOptions
    ).then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        });
}

function getProducts (catId,pNumber,search,orderBy,lang){
    const body = {
        category_id: catId,
        page: pNumber,
        size: 25,
        search: search,
        orderBy: [
            orderBy
        ]
    };

    const requestOptions={
        method: "POST",
        headers:{
            "Accept-Language": lang,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(body),
    };

    return fetch(`${API_URL}/api/products`,requestOptions)
        .then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        })
}

function sendEmailCarreer (formData,lang){
    const requestOptions={
        method: "POST",
        headers:{
            "Accept-Language": lang,
            "X-Requested-With": "XMLHttpRequest",
        },
        body: formData,
        redirect: "follow"
    };
    return fetch(`${API_URL}/api/send-careers-message`,requestOptions)
        .then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        })
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        const responseData = {
            success: false,
            message: null,
            totalRecords: 0,
            totalPages: 0,
            data: data,
            error: false,
        };

        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function sendeMailcontact (name,email,phone,subject,message,priPol,lang){
    const body = {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
        privacyPolicy: priPol
    };

    const requestOptions={
        method: "POST",
        headers:{
            "Accept-Language": lang,
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(body),
    };

    return fetch(`${API_URL}/api/send-contact-message`,requestOptions)
        .then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        })
}

function loginAdmin (email,pass){
    const body = {
        email: email,
        password: pass,
    };

    const requestOptions={
        method: "POST",
        headers:{
            "Content-Type": 'application/json'
        },
        body: JSON.stringify(body),
    };

    return fetch(`${API_URL}/api/login`,requestOptions)
        .then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        })
}

function categoryList (token){
    const body = {
        "page": 1,
        "size": -1,
        "status": "1",
        "search": ""
    }
    const requestOptions = {
        method: "POST",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(body),
    };

    return fetch(
        `${API_URL}/api/auth/categories/list`,
        requestOptions
    ).then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        });
}


function updateCat (token, order, spanish, englis, status,id){
    const body =  {
        "order": order,
        "spanish": spanish,
        "english": englis,
        "status": status
    }
    const requestOptions = {
        method: "PUT",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(body),
    };
    return fetch(
        `${API_URL}/api/auth/categories/${id}`,
        requestOptions
    ).then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        });
}

function addCategory (token, spanish, englis){
    const body =  {
        "spanish": spanish,
        "english": englis
    }
    const requestOptions = {
        method: "POST",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(body),
    };
    return fetch(
        `${API_URL}/api/auth/categories/create`,
        requestOptions
    ).then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        });
}

function adminProductList (pageParameters, searchParameters){
    const { page, size, orderBy } = pageParameters;
    const { category, status, search } = searchParameters;
    const token = JSON.parse(localStorage.getItem('access_token'));

    const body =  {
        page: page +1,
        size: size,
        status: status,
        category_id: category,
        search: search,
        orderBy: [
            orderBy
        ]
    }
    const requestOptions = {
        method: "POST",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(body),
    };
    return fetch(`${API_URL}/api/auth/products/list`, requestOptions)
        .then(handleResponse)
        .then((jsonData)=> {
            return jsonData;
        });
}

function updateProduct (id, body){
    const token = JSON.parse(localStorage.getItem('access_token'));

    const requestOptions = {
        method: "PUT",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
            "Content-Type": 'application/json',
        },
        body: JSON.stringify(body),
    };

    return fetch(`${API_URL}/api/auth/products/${id}`, requestOptions)
        .then(handleResponse)
        .then((jsonData)=> { return jsonData; });
}


function logOut (token){

    const requestOptions = {
        method: "POST",
        headers: {
            'X-Requested-With': "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
        },
    };
    return fetch (
        `${API_URL}/api/auth/logout`,
        requestOptions
    ).then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        });
}

function updateProductImg (idProd, formData){
    const token = JSON.parse(localStorage.getItem('access_token'));

    const requestOptions= {
        method: "POST",
        headers:{
            "X-Requested-With": "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
    };
    return fetch(`${API_URL}/api/auth/products/${idProd}/update_cover`, requestOptions)
        .then(handleResponse)
        .then((jsonData)=>{
            return jsonData;
        })
}

function createProduct (formData){
    const token = JSON.parse(localStorage.getItem('access_token'));

    const requestOptions={
        method: "POST",
        headers:{
            "X-Requested-With": "XMLHttpRequest",
            'Authorization': `Bearer ${token}`,
        },
        body: formData,
    };
    return fetch(`${API_URL}/api/auth/products/create`,requestOptions)
        .then(handleResponse)
        .then((jsonData)=> {
            return jsonData;
        })
}

 