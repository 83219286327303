import React, {useState} from 'react';
import './App.css';
import { RouterPrincipal } from './routers/RouterPrincipal';
export const langContext = React.createContext();


function App() {

const [lang, setLang] = useState('es'); 
const [titPlaceAd, setTitPlaceAd] = useState('');
  return (
    <langContext.Provider value={{lang, setLang, titPlaceAd, setTitPlaceAd}}>
        <RouterPrincipal />
    </langContext.Provider>
        
       
   
  );
}

export default App;
