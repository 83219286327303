import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Color principal
    },
    secondary: {
      main: '#dc004e', // Color secundario
    },
    error: {
      main: '#f44336', // Color de error
    },
    background: {
      default: '#f5f5f5', // Color de fondo por defecto
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.75rem',
    },
    h5: {
      color: 'black !important',
    }
  },
  components: {
    MuiInputLabel: {
      color: 'black',
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
  },
});

export default theme;