import React from 'react'
import Footer from './commons/Footer'
import './unete.css'
import { useState, useContext } from 'react';
import { langajes } from '../_helpers/languajes';
import { langContext } from '../App';
import { categoryService } from '../_services/category.services';
import Header from './commons/Header';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



export const Unete = () => {

const [mail, setMail] = useState("");
const [nombre, setNombre] = useState("");
const [tel, setTel] = useState("");
const [message, setMessage] = useState("");
const [attatchment, setAttatchment] = useState();
const [checkState, setCheckstate] = useState(false);
const [sendOK, setSendOK] = useState(false);
const l = useContext(langContext);
const [open, setOpen] = useState(false);

const idioma = ()=>{
    if(l.lang==='es'){
       return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
}

const handleAddFile = (e) =>{
    setAttatchment(e.target.files[0]);
}

const handleChangeCheck = (e) => {
    setCheckstate(e.target.checked);
}

const handleSubmmit =  async (e) =>{
    setOpen(true);
    e.preventDefault();
    let formData = new window.FormData();
        formData.append('name',nombre);
        formData.append('email',mail);
        formData.append('phone', tel);
        formData.append('message', message);
        formData.append('files', attatchment);
        formData.append('privacyPolicy', checkState);   
    try{
        const response  = await categoryService.sendEmailCarreer(formData, l.lang);
        console.log('Correo enviado correctamente');
        if(response.success === true){
            setOpen(false);
            setSendOK(true);
            
        }
        }catch(error){
            console.log(error);
            setOpen(false);
        }
    
    
}


  return (
    <div className='row'>
        <Header/>
        <div className='col-12 uneteContainer'>
            <div className='textContainerJoinus'>
               <h1 className='h1Join'>{idioma().unete.tituloUnete}</h1> 
               <p className='pJoin'>{idioma().unete.textoUnete}</p>
            </div>
        </div>
        <div className='row'>
                <div className='col-sm-12 col-lg-6 colText d-flex justify-content-center align-items-center'>
                    <div className='row '>
                        <div className='col-12 '>
                            <h3 className=''>{idioma().unete.linea1} </h3>
                            <h3 className=''>{idioma().unete.linea2}</h3>
                            <h3 className=''>{idioma().unete.linea3} </h3>
                        </div>
                        <div className='col-12 colText txtHorario'>
                            <h3 className=''> {idioma().unete.linea4}</h3>
                            <h3 className=''>{idioma().unete.linea5}</h3>
                        </div>
                        
                    </div>
                </div>
                <div className='formCol col-sm-12 col-lg-6 justify-content-center'>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {sendOK === true ? (
                        <h1>{idioma().contacto.sendResp}</h1>
                    ):(
                         <form id="formJoin" onSubmit={handleSubmmit} className='formSt'> 
                         <label >{idioma().unete.formulario1}</label>
                         <input id="nombre" name='name' type="text" required
                             onChange={e => setNombre(e.target.value)}
                         />
                         <label >Email:*</label>
                         <input id="email" name='email' type='email' required
                             onChange={e => setMail(e.target.value)}
                         />
                         <label>{idioma().unete.formulario2}</label>
                         <input id='tel' name='phone' type='tel' required
                             onChange={e => setTel(e.target.value)}
                         />
                         <label>{idioma().unete.formulario3}</label>
                         <textarea id='mensaje' name='message' required
                             onChange={e => setMessage(e.target.value)}
                         />
                         <label>{idioma().unete.formulario4}</label>
                         <input 
                             required
                             name='file'
                             id='adjunto' 
                             type='file' 
                             accept='.doc,.docx,.pdf'
                             onChange={(e) =>handleAddFile(e)}
                             />
                         <label className='labCheck'>
                           <input 
                             required
                             onChange={handleChangeCheck}
                             type='checkbox' name='politica' /><p className='pPol'>{idioma().contacto.checkPol} <a className='alinkPol'>{idioma().contacto.checklink}</a></p>
                         </label>
                         <div className='btnContainer'>
                             <button className='linkSendMail'>{idioma().unete.boton}</button>
                         </div>
                     </form>
                    )}
                   
                </div>
            </div>

        <Footer/>
    </div>
  )
}
