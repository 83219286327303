import React from "react";
import './us.css';
import { useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';


const Us = () =>{

const l = useContext(langContext);

const idioma = ()=>{
    if(l.lang==='es'){
        return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
};


    return (
        <div className="container usLayout">
            <div className="row">
                <div className="col-12 titleUs">
                    <h1 >{idioma().nosotros.titulo}</h1>
                </div>
            </div>
            <div className="row usContent">
                <div  className="col-md-12 col-lg-7 usImage">
                    <img src="usImage.png" alt={""}/>
                </div>
                <div className="col-md-12 col-lg-5 usText">
                    <h1>{idioma().nosotros.tituloNos}</h1>
                    <h3>{idioma().nosotros.textParag1}<br/><br/>{idioma().nosotros.textParag2}<br/><br/>{idioma().nosotros.textParag3}</h3>
                </div>
            </div>
            
        </div>
    );
};

export default Us;