import React, { useEffect, useState } from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TableContainer from "@mui/material/TableContainer";
import "./flyerDataTable.css";
import TablePagination from "@mui/material/TablePagination";
import { getAdminFlyerList, toggleFlyerStatus } from "../../../../../_services/flyer.services";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from '@mui/icons-material/Delete';
import { IOSSwitch, StyledTableCell, StyledTableRow } from "../../../../commons/StyledComponents";
import Button from "@mui/material/Button";

export const FlyerDataTable = (
  {
    loading,
    setLoading,
    fetchData,
    setFetchData,
    flashMessage,
    error,
    setError,
    flyers,
    setFlyers,
    pageParameters,
    setPageParameters,
    searchParameters,
    onFlyerUpdate,
    handleUpdateFlyerForm,
    handleDeleteFlyer
  }) => {
  const { page, size, orderBy } = pageParameters;
  const { search, status } = searchParameters;
  const [totalRecords, setTotalRecords] = useState(0)
  const columns = [
    { field: "id" },
    { field: "name" },
    { field: "heyzine_id" },
    { field: "url" },
    { field: "thumbnail_url" },
    { field: "enabled" },
  ];

  useEffect(() => {
    if (fetchData) {
      handleFetchFlyerList().finally(onFinally => {
        setLoading(false)
        setFetchData(false)
      });
    }
  }, [fetchData]);

  const handleChangePage = (event, newPage) => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: newPage
    }))

    setFetchData(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: 0,
      size: event.target.value
    }))

    setFetchData(true)
  };

  const handleSort = (event, field) => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      orderBy: {
        field: field,
        direction: orderBy.field === field && orderBy.direction === 'asc' ? 'desc' : 'asc'
      },
    }))

    setFetchData(true)
  }

  const handleFetchFlyerList = async () => {
    try {
      setLoading(true)

      await getAdminFlyerList(pageParameters, searchParameters).then(( response ) => {
        const {data, totalRecords} = response;
        setFlyers(data)
        setTotalRecords(totalRecords);
      });
    } catch (error) {
      setError(error)
    }
  }

  const handleChangeStatus = async ({id, enabled}) => {
    try {
      await toggleFlyerStatus(id, !enabled).then((response) => {
        if (response.status === 200) {
          onFlyerUpdate(response.data);
          flashMessage(response.message, ("success"));
        } else if (response.error) {
          flashMessage(response.error, ("error"));
        }
      })
    } catch (error) {
      flashMessage(error, "error");
    }
  }

  return (
    <div>
      <TableContainer component={ Paper }>
        <Table sx={ { minWidth: 700 } } aria-label="customized table">
          <TableHead sx={ { height: '50px' } }>
            <TableRow style={{ fontWeight: 800 }}>
              <StyledTableCell align={"center"}>
                <TableSortLabel active={columns[0].field === orderBy.field} direction={ orderBy.direction } onClick={(e)=> handleSort(e, columns[0].field)}>
                  ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={"center"}>
                <TableSortLabel active={columns[1].field === orderBy.field} direction={ orderBy.direction } onClick={(e)=> handleSort(e, columns[1].field)}>
                  Nombre
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={"center"}>
                <TableSortLabel active={columns[2].field === orderBy.field} direction={ orderBy.direction } onClick={(e)=> handleSort(e, columns[2].field)}>
                  Heyzine ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={"center"}>
                <TableSortLabel active={columns[3].field === orderBy.field} direction={ orderBy.direction } onClick={(e)=> handleSort(e, columns[3].field)}>
                  URL
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={"center"}>
                Miniatura
              </StyledTableCell>
              <StyledTableCell align={"center"}>
                <TableSortLabel active={columns[5].field === orderBy.field} direction={ orderBy.direction } onClick={(e)=> handleSort(e, columns[5].field, (columns[5].field === orderBy.field ? orderBy.direction : 'asc' ))}>
                  Estatus
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell  align={"center"}>
                Opciones
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { loading ?
              (
                <StyledTableRow>
                  <StyledTableCell colSpan={100} align={'center'} style={{ fontSize: '1.1rem'}} >Loading...</StyledTableCell>
                </StyledTableRow>
              ) : (
                flyers.map(
                  flyer => (
                    <StyledTableRow key={ flyer.id } >
                      <StyledTableCell align={"center"}>{ flyer.id }</StyledTableCell>
                      <StyledTableCell align={"center"}>{ flyer.name }</StyledTableCell>
                      <StyledTableCell align={"center"}>{ flyer.heyzine_id }</StyledTableCell>
                      <StyledTableCell align={"center"}>{ flyer.url }</StyledTableCell>
                      <StyledTableCell align={"center"} style={{ width: '120px', overflow: "hidden" }}>
                        <img src={ flyer.thumbnail_url } alt="" style={{ padding: '8px', width: '100%' }} />
                      </StyledTableCell>
                      <StyledTableCell align={"center"}>
                        <FormGroup>
                          <FormControlLabel
                            checked={ !!flyer.enabled }
                            onClick={ () => { handleChangeStatus(flyer) } }
                            sx={{marginRight: 0, marginLeft: 0, justifyContent: 'center'}}
                            control={<IOSSwitch sx={{ m: .5 }} />}
                            label={""}
                          />
                        </FormGroup>
                      </StyledTableCell>
                      <StyledTableCell align={"center"}>
                        <Button onClick={ (e) => handleUpdateFlyerForm(e, flyer) }>
                          <EditNoteIcon sx={{ color:'#5fB4F9', fontSize: "2.2em" }} />
                        </Button>

                        <Button onClick={ e => handleDeleteFlyer(e, flyer) }>
                          <DeleteIcon sx={{ color:'#dc1616', fontSize: "1.8em" }} />
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="pageAdminFlyer"
        rowsPerPageOptions={ [30, 60, 240] }
        component="div"
        count={totalRecords}
        rowsPerPage={size}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />
    </div>
  )
}
