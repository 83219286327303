import React from "react";
import './brandprods.scss';
import {useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';

const BrandProds = () =>{

    const l = useContext(langContext);

    const idioma = ()=>{
        if(l.lang==='es'){
           return langajes.spanish
        }else if(l.lang==='en'){
            return langajes.english
        }
    }
    
    return (
        <div className="contentBrands">
            

            <div className="slider">
                <div className="slide-track">
                    <div className="slide">
                        <img src="cocacola.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="costena.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="colgate.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="lucas.jpg" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="topochico.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="cocacola.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="costena.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="colgate.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="lucas.jpg" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="topochico.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="cocacola.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="costena.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="colgate.png" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="lucas.jpg" height="100" width="auto" alt={""} />
                    </div>
                    <div className="slide">
                        <img src="topochico.png" height="100" width="auto" alt={""} />
                    </div>
                </div>
            </div>

            <div className="linkPrdsContainer">
                <a  href="/productos"><p className="prodsLink">{idioma().header.navItem2}</p></a>
            </div>

        </div>
    );
};

export default BrandProds;
