import React, {useContext, useEffect, useState} from 'react';
import { HeaderAd } from "../../HeaderAd";
import { langContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import "./adminproductsHome.css"
import Button from '@mui/material/Button';
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ProductDataTable } from "./DataTable/ProductDataTable";
import AddIcon from "@mui/icons-material/Add";
import { categoryService } from "../../../_services/category.services";
import { ProductSearchBar } from "./ProductSearchBar/ProductSearchBar";
import { FlashMessage } from "../../commons/FlashMessage/FlashMessage";
import { ProductForm } from "./ProductForm/ProductForm";

export const AdminProductsHome = () => {
    const t = useContext(langContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [fetchProductData, setFetchProductData] = useState(false)
    const [fetchCategoryData, setFetchCategoryData] = useState(false)
    const [product, setProduct] = useState({} )
    const [searchParameters, setSearchParameters] = useState({category: 0, status: 2, search: ""})
    const [pageParameters, setPageParameters] = useState({
        page: 0,
        size: 30,
        orderBy: {
            field: "id",
            direction: "desc"
        }
    })
    const [flashMessage, setFlashMessage] = useState({message: "", severity: "success", expanded: false, timeout: 4000})

    useEffect(() => {
        t.setTitPlaceAd('Productos');
        setFetchCategoryData(true)
    },[]);

    useEffect(() => {
        if (fetchCategoryData) {
            fetchCategories().finally(() => {
                setLoading(false)
                setFetchCategoryData(false)
                setFetchProductData(true)
            });
        }
    }, [fetchCategoryData])


    const handleRegresar = () => {
        navigate('/admin');
    }

    function resetProductInfo() {
        setIsEditMode(false);

        setProduct({
            id: null,
            sku: '',
            description: '',
            category_id: '',
            sub_category: '',
            product_line: '',
            status: false,
            cover: {}
        })
    }

    const handleOpenModalEdit = (e, productInfo)=> {
        setIsEditMode(true);
        setProduct({
            ...productInfo,
            cover: {...productInfo.cover}
        })

        setTimeout(() => {
            setIsFormModalOpen(true);
        }, 500)
    }

    const handleOpenCreateProd = () => {
        setIsEditMode(false);
        resetProductInfo()

        setTimeout(() => {
            setIsFormModalOpen(true);
        }, 500)
    }

    const handleSetMessage = (message, severity = "success", timeout = 3000) => {
        setFlashMessage({
            message: message,
            severity: severity,
            timeout: timeout,
            expanded: true
        });
    }

    const handleProductUpdate = (updatedProduct) => {
        setProducts(prevProducts => {
            const existingProductIndex = prevProducts.findIndex(product => product.id === updatedProduct.id);

            if (existingProductIndex >= 0) {
                const updatedProducts = [...prevProducts];
                updatedProducts[existingProductIndex] = updatedProduct;
                return updatedProducts;
            } else {
                return [...prevProducts, updatedProduct];
            }
        });
    }

    const fetchCategories = async () => {
        setLoading(true);

        try {
            await categoryService.getCategories("es").then((response) => {
                setCategories(response?.data)
            });
        } catch (error){
            handleSetMessage(error, "error")
        }
    }

    return(
        <div>
            <HeaderAd/>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="contTable">
                <FlashMessage flashMessage={flashMessage} setFlashMessage={setFlashMessage}></FlashMessage>

                <div className='row headContAdProds'>
                    <div className='col-12'>
                        <Button
                            onClick={ handleRegresar }
                            sx={{border: '1px solid black', borderRadius: '15px', color: '#000'}}
                            variant="outlined"
                            startIcon={<ArrowBackIosNewIcon/>}>
                            Regresar
                        </Button>
                    </div>
                </div>

                <div className={ "row m-2" }>
                    <div className='col-md-3 col-sm-12 d-flex justify-content-start align-items-center'>
                        <Button
                          onClick={ handleOpenCreateProd }
                          sx={ {
                              border: '1px solid black',
                              borderRadius: '15px',
                              color: '#000',
                              height: 'fit-content'
                          } }
                          variant="outlined"
                          endIcon={ <AddIcon sx={{ color: '#20c997' }}/> }
                        >
                            Agregar Nuevo
                        </Button>
                    </div>
                    <div className={"col-md-9 col-sm-12"}>
                        <ProductSearchBar
                          categories={categories}
                          pageParameters={pageParameters}
                          setPageParameters={setPageParameters}
                          searchParameters={searchParameters}
                          setSearchParameters={setSearchParameters}
                          setFetchProductData={setFetchProductData}
                        />
                    </div>
                </div>
                <ProductDataTable
                  style={ { paddingTop: "10px" } }
                  handleOpenModalEdit={ handleOpenModalEdit }
                  flashMessage={ handleSetMessage }
                  loading={ loading }
                  setLoading={ setLoading }
                  pageParameters={pageParameters}
                  setPageParameters={setPageParameters}
                  searchParameters={ searchParameters }
                  setSearchParameters={ setSearchParameters }
                  fetchProductData={ fetchProductData }
                  setFetchProductData={ setFetchProductData }
                  products={ products }
                  setProducts={ setProducts }
                  categories={ categories }
                  setCategories={ setCategories }
                  onProductUpdate={ handleProductUpdate }
                />

                <ProductForm
                  setFetchData={ setFetchProductData }
                  isFormModalOpen={ isFormModalOpen }
                  setIsFormModalOpen={ setIsFormModalOpen }
                  isEditMode={ isEditMode }
                  product={ product }
                  setProduct={ setProduct }
                  categories={ categories }
                  loading={loading}
                  setLoading={setLoading}
                  onProductUpdate={ handleProductUpdate }
                  flashMessage={handleSetMessage}
                />
            </div>
        </div>
    );
}