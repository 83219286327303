
import './header.css';
import {NavLink} from 'react-router-dom';
import { useState, useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';
import WhatsAppFloatIcon from "../WhatsApp/WhatsAppFloatIcon";


const Header = () =>{
    const [stateMenu, setStateMenu] = useState("menuHide");
    

    const l = useContext(langContext);

    const handleSetStateMenu = (state) =>{
        if(state === "menuHide"){
            setStateMenu("menuShow");
        }else if(state === "menuShow"){
            setStateMenu("menuHide");
        }
    }
    const handleCloseMenu = ()=>{
        setStateMenu("menuHide");
    }

const idioma = ()=>{
    if(l.lang==='es'){
       return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
}





 return (
        <header>
            <div className={`row hStyle containerAlign`}>
            <div className="colImg col-6 col-lg-4">
                    <NavLink  to="/">
                        <img className="imgH" width="150px" src="logoBredy.png" alt={""}/>
                    </NavLink>
                </div>
                <div className="colNav d-none d-lg-block col-lg-8">
                    <div className='row'>
                        <div className='contNavFlag'>
                            <nav>
                                <NavLink className= {({isActive})=> isActive ? 'linkActivo itemNav':'itemNav'} to="/">{idioma().header.navItem1}</NavLink>
                                <NavLink className= {({isActive})=> isActive ? 'linkActivo itemNav':'itemNav'}  to="/productos">{idioma().header.navItem2}</NavLink>
                                <NavLink className= {({isActive})=> isActive ? 'linkActivo itemNav':'itemNav'}  to="/flyer">{idioma().header.navItem3}</NavLink>
                                <NavLink className= {({isActive})=> isActive ? 'linkActivo itemNav':'itemNav'}  to="/unete">{idioma().header.navItem4}</NavLink>
                                <NavLink className= {({isActive})=> isActive ? 'linkActivo itemNav':'itemNav'}  to="/contacto">{idioma().header.navItem5}</NavLink>
                            </nav>
                            <div>
                                <p>
                                    <img onClick={()=>l.setLang('es')} className="imgMxFlag" src="mxFlag.svg" alt={""}/>
                                    <img onClick={()=>l.setLang('en')} className="imgUsFlag" src="usaFlag.svg" alt={""}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-lg-none d-xl-none col-6 menuStyle">
                    <img width="45px" src="manu.svg" onClick={()=>handleSetStateMenu(stateMenu)} alt={""}/>
                </div>
                <nav className={`${stateMenu} navMob d-lg-none d-xl-none col-7`}>
                    <ul >
                        <li>
                            <NavLink className="itemNav" to="/"
                                onClick={()=>handleCloseMenu()}
                            >{idioma().header.navItem1}</NavLink>
                        </li>
                        <li>
                            <NavLink className="itemNav" to="/productos"
                                onClick={()=>handleCloseMenu()}
                            >{idioma().header.navItem2}</NavLink> 
                        </li>
                        <li>
                             <NavLink className="itemNav" to="/flyer"
                                onClick={()=>handleCloseMenu()}
                             >{idioma().header.navItem3}</NavLink>
                        </li>
                        <li>
                            <NavLink className="itemNav" to="/unete"
                                onClick={()=>handleCloseMenu()}
                            >{idioma().header.navItem4}</NavLink>
                        </li>
                        <li>
                            <NavLink className="itemNav" to="/contacto"
                                onClick={()=>handleCloseMenu()}
                            >{idioma().header.navItem5}</NavLink>
                        </li>
                        <li>
                            <p>
                                <img onClick={()=>l.setLang('es')} className="imgMxFlag" src="mxFlag.svg" alt={""}/>
                                <img onClick={()=>l.setLang('en')} className="imgUsFlag" src="usaFlag.svg"  alt={""}/>
                            </p>
                        </li>
                    </ul>
                </nav>
                <WhatsAppFloatIcon/>
            </div>
        </header>
 );
};

export default Header;