import React, {useEffect,useState} from 'react';
import './login.css';
import { categoryService} from '../_services/category.services';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import {useNavigate} from 'react-router-dom'




export const Login = () => {
const [expanded, setExpanded] = React.useState(false);
const [usr, setUsr] = useState();
const [pss, setPss] = useState();
const [errorMessage, setErrorMessage] = useState();

const ajusteLogin = () =>{
    if(window.location.pathname === '/login'){
        const alto = document.getElementsByTagName('body');  
        console.log(alto[0].setAttribute('className', 'height: 100%'));
    }   
}

useEffect(()=>{
    if(localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined){
        navigate('/admin');
    }
})

useEffect(()=>{
    ajusteLogin();
})

const handChangeInputLog = (e) =>{
    if(e.target.name === 'usuario'){
        setUsr(e.target.value);
    }else if(e.target.name === 'pass'){
        setPss(e.target.value);
    }
    
}
const navigate = useNavigate();
const handleLogin = async (e) => {
    e.preventDefault();
    try{
        const response = await categoryService.loginAdmin(usr,pss);
        console.log(response)
        localStorage.setItem("user", JSON.stringify(response.user));
        localStorage.setItem("access_token", JSON.stringify(response.access_token));
        navigate('/admin');
    }catch(error){
        setErrorMessage(error);
        setExpanded(true);
        setTimeout(()=>{
            setExpanded(false);
        },3000);
    }
    
}

  return (
    <div className='altoLogin'>
        <div className='row contLogin d-flex justify-content-center align-items-center'>
            <div className='colImgLog col-sm-12 col-md-6'>
                <a href='/'><img className='logoLog' src='logoBredy.png' alt={""}/></a>
            </div>
           <div className='colFormLog col-sm-12 col-md-6'>
                <form className='formLog' onSubmit={handleLogin}>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Alert severity="error">{errorMessage} </Alert>
                    </Collapse>
                    <label>Usuario</label>
                    <input type='email' name='usuario' autoComplete='username' className='inputLogin' required placeholder='usuario@bredy.com'
                        onChange={handChangeInputLog}
                    />
                    <label>Contraseña</label>
                    <input type='password' name='pass' autoComplete='current-password' className='inputLogin' required
                        onChange={handChangeInputLog}
                    />
                    <div className='btnCont'>
                         <button className='btnLogin'>Iniciar sesión</button>
                    </div>
                </form>
           </div>
        </div>
    </div>
  )
}
