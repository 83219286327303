import axios from 'axios';

const API_URL = process.env.REACT_APP_API;

const handleArrayResponse = (response) => {
  if (response.status === 200) {
    const { success, message, currentPage, totalRecords, totalPages, data } = response.data
    return {
      status: response.status,
      success: success,
      message: message,
      currentPage: currentPage,
      totalRecords: totalRecords,
      totalPages: totalPages,
      data: data.map((data) => ({
        id: data.id,
        name: data.name,
        uuid: data.uuid,
        heyzine_id: data.heyzine_id,
        url: data.url,
        thumbnail_url: data.thumbnail_url,
        pdf_url: data.pdf_url,
        enabled: data.enabled
      }))
    }
  } else {
    const { success, message, error } = response.data
    return {
      status: response.status,
      success: success,
      message: message,
      error: error
    }
  }
}

const handleSingleResponse = (response) => {
  if (response.status === 200) {
    const { success, message, data } = response.data
    return {
      status: response.status,
      success: success,
      message: message,
      data: data
    }
  } else {
    const { success, message, error } = response.data
    return {
      status: response.status,
      success: success,
      message: message,
      error: error
    }
  }
}

export const getLatestFlyer = async () => {
  try {
    return await axios.get(`${API_URL}/api/flyer/latest`).then(handleSingleResponse);
  } catch (error) {
    throw error;
  }
};

export const getAdminFlyerList = async ({ page, size, orderBy }, { search, status }) => {
  const token = JSON.parse(localStorage.getItem('access_token'));

  try {
    const body = {
      page: page + 1,
      size: size,
      search: search,
      enabled: status,
      orderBy: [
        orderBy
      ]
    }

    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest'
      }
    };

    return await axios.post(`${API_URL}/api/auth/flyer`, body, headers).then(handleArrayResponse);
  } catch (error) {
    throw error;
  }
}

export const uploadFlyerFile = async (id, flyer) => {
  const token = JSON.parse(localStorage.getItem('access_token'));

  try {
    const body = new FormData();
    if (id) body.append("id", id);
    body.append("flyer", flyer);

    const headers = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'X-Requested-with': 'XMLHttpRequest'
      }
    };

    return await axios.post(`${API_URL}/api/auth/flyer/upload`, body, headers).then(handleSingleResponse)
  } catch (error) {
    throw error;
  }
}

export const deleteFlyer = async (id) => {
  const token = JSON.parse(localStorage.getItem('access_token'));

  try {
    const headers = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        'X-Requested-with': 'XMLHttpRequest'
      }
    };

    return await axios.delete(`${API_URL}/api/auth/flyer/${id}`, headers).then(handleSingleResponse)
  } catch (error) {
    throw error;
  }
}

export const toggleFlyerStatus = async (id, enabled) => {
  const token = JSON.parse(localStorage.getItem('access_token'));

  try {
    const body = {
      id: id,
      enabled: enabled
    }

    const headers = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Requested-with': 'XMLHttpRequest'
      }
    };

    return await axios.patch(`${API_URL}/api/auth/flyer`, body, headers).then(handleSingleResponse);
  } catch (error) {
    throw error;
  }
}
