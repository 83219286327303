import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { InputLabel, Select, MenuItem, Box, TextField } from "@mui/material";
import { IOSSwitch } from "../../../commons/StyledComponents";
import { categoryService } from "../../../../_services/category.services";
import FormControl from "@mui/material/FormControl";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: 0,
  width: '75vw',
  maxWidth: '1200px',
  paddingBottom: '50px',
};

export const ProductForm = ({ setFetchData, isFormModalOpen, setIsFormModalOpen, isEditMode, product, setProduct, categories, loading, setLoading, onProductUpdate, flashMessage }) => {
  const [attachment, setAttachment] = useState(undefined);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setProduct(prevProduct => ({
      ...prevProduct,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = (e) => {
    e.preventDefault()
    setAttachment(e.target.files[0])
  };

  const handleCloseModal = () => {
    setIsFormModalOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)

      if (isEditMode) {
        const body = {
          "sku": product.sku,
          "description": product.description,
          "category_id": product.category_id,
          "sub_category": product.sub_category,
          "product_line": product.product_line,
          "status": product.status
        }

        await categoryService.updateProduct(product.id, body).then((response) => {
          if (typeof response?.success !== 'undefined' && response.success) {
            flashMessage(response.message, 'success')
            setProduct(response.data)
            onProductUpdate(response.data)
          }
        });
      } else {
        const formData = new FormData();
        formData.append("sku", product.sku);
        formData.append("description", product.description);
        formData.append("category_id", product.category_id);
        formData.append("product_line", product.product_line);
        formData.append("sub_category", product.sub_category);

        if(typeof attachment !== 'undefined') {
          formData.append("cover", attachment);
        }

        await categoryService.createProduct(formData).then((response) => {
          if (typeof response?.success !== 'undefined' && response.success) {
            flashMessage(response.message, 'success')
            setFetchData(true)

            setTimeout(() => {
              setIsFormModalOpen(false)
            }, 2000)
          }
        });
      }
    } catch (error) {
      flashMessage(error, "error")
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateProdImage  = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("cover", attachment);

      await categoryService.updateProductImg(product.id, formData).then((response) => {
        if (typeof response?.success !== 'undefined' && response.success) {
          flashMessage(response.message, 'success')
          setProduct(response.data)
          onProductUpdate(response.data)
        }
      });
    } catch (error) {
      flashMessage(error, "error")
    } finally {
      setLoading(false)
    }
  };

  return (
    <Modal
      open={isFormModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='row'>
          {
            !isEditMode ? (
              <h1 className='titModalEdit'>Registrar Producto:</h1>
            ) : (
              <h1 className='titModalEdit'>Editar Producto:</h1>
            )
          }
        </div>
        <div className='row'>
          <div className='col-5 imgEditM'>
            {
              !isEditMode || product?.cover?.file_path === undefined ? (
                <div style={ { width: "100%", height: "85%", backgroundColor: "#eee" } }></div>
              ) : (
                <img src={ product.cover?.file_path } alt='cover'/>
              )
          }

          <form onSubmit={ (e) => handleUpdateProdImage( e ) }>
              <label>Subir archivo</label>
                <input
                  required={ !isEditMode }
                  id='adjunto'
                  name='cover'
                  type='file'
                  accept='image/*'
                  onChange={ handleImageChange }
                />
                {
                  isEditMode ? (
                    <div className='divBtnEdit'>
                      <button className='btnEdit' disabled={loading} >
                        Guardar
                      </button>
                    </div>
                  ) : ''
                }
            </form>
          </div>
          <div className='col-7 colContForm'>
            <form className='divInputsEdit' onSubmit={ handleSubmit }>
              <div className='catEditTitSts' hidden={ !isEditMode }>
                <IOSSwitch
                  name="status"
                  checked={ !!product.status }
                  onChange={handleChange}
                  sx={ { marginRight: 0, marginLeft: 0, justifyContent: 'center' } }
                  control={ <IOSSwitch sx={{ m: .5 }}/> }
                  label={ "" }
                />
              </div>
              <FormControl sx={{ mt:2, mb:2 }}>
                <TextField
                  label={"Nombre"}
                  required={ true }
                  name={ "description" }
                  value={ product.description }
                  onChange={ handleChange }
                />
              </FormControl>
              <FormControl sx={{ mt:2, mb:2 }}>
                <TextField
                  label="SKU"
                  required={ true }
                  name={ "sku" }
                  value={ product.sku }
                  onChange={ handleChange }
                />
              </FormControl>
              <FormControl sx={{ mt:2, mb:2 }}>
                <InputLabel id={"selectCategoryLabel"}>Categoría</InputLabel>
                <Select
                  labelId={"selectCategoryLabel"}
                  required={ true }
                  name={ "category_id" }
                  value={ product.category_id }
                  onChange={ handleChange }
                  variant={"outlined"}
                >
                  {
                    categories.map(
                      (category) => (
                        <MenuItem key={ category.id } value={ category.id }>
                          { category.name }
                        </MenuItem>
                      )
                    )
                  }
                </Select>
              </FormControl>
              <FormControl sx={{ mt:2, mb:2 }}>
                <TextField
                  label={ "Sub Categoría" }
                  name="sub_category"
                  onChange={ handleChange }
                  value={ product.sub_category || '' }
                />

              </FormControl>
              <FormControl sx={{ mt:2, mb:2 }}>
                <TextField
                  label="Línea de producto"
                  name="product_line"
                  onChange={ handleChange }
                  value={ product.product_line || '' }
                />
              </FormControl>
              <div className='divBtnEdit'>
                <button type='submit' className='btnEdit'>Guardar</button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  )
}