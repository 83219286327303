import React from "react";
import Alert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

export const FlashMessage = ({ flashMessage, setFlashMessage }) => {
  const { message, severity, timeout, expanded } = flashMessage

  const handleClose = (event, reason) => {
    setFlashMessage((prevState) => ({
      ...prevState,
      expanded: false
    }))
  };

  return (
    <Snackbar open={expanded} autoHideDuration={ timeout } onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert onClose={handleClose} severity={severity} variant="filled" >
        { message }
      </Alert>
    </Snackbar>
  )
}