import React from 'react';
import Videocomponent from '../components/Home/Videocomponent';
import Us from '../components/Home/Us'
import MisionVision from '../components/Home/MisionVision';
import BrandProds from '../components/Home/BrandProds';
import Delivery from '../components/Home/Delivery';
import ContacLink from '../components/Home/ContactLink';
import Footer from '../components/commons/Footer'
import Header from './commons/Header';

export const Home = () => {
  return (
    <div className="App">
      <Header/>
      <Videocomponent urlVideo="video1.mp4" />
      <Us/>
      <MisionVision/>
      <Videocomponent urlVideo="video2.mp4" />
      <BrandProds/>
      <Delivery />
      <ContacLink />
      <Footer/>
    </div>
  )
}
