import React, { useContext, useEffect, useState } from 'react';
import { HeaderAd } from "../../HeaderAd";
import { langContext } from "../../../App";
import { useNavigate } from 'react-router-dom';
import "./adminFlyer.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import { FlyerSearchBar } from "./components/SearchBar/FlyerSearchBar";
import { FlashMessage } from "../../commons/FlashMessage/FlashMessage";
import { FlyerDataTable } from "./components/DataTable/FlyerDataTable";
import { FlyerForm } from "./components/FlyerForm/FlyerForm";

export const AdminFlyerHome = () => {
  const context = useContext(langContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const [error, setError] = useState("");
  const [flyers, setFlyers] = useState([])
  const [flashMessage, setFlashMessage] = useState({message: "", severity: "success", expanded: false, timeout: 4000})
  const [pageParameters, setPageParameters] = useState( {
    page: 0,
    size: 30,
    orderBy: {
      field: "id",
      direction: "desc"
    }
  })
  const [searchParameters, setSearchParameters] = useState({status: 2, search: ""})

  const [flyer, setFlyer] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  useEffect(() => {
    context.setTitPlaceAd('Flyer Mensual');
    setFetchData(true)
  },[])

  const handleRegresar = () => {
    navigate('/admin');
  }

  const handleSetMessage = (message, severity = "success", timeout = 3000) => {
    setFlashMessage({
      message: message,
      severity: severity,
      timeout: timeout,
      expanded: true
    });
  }

  const handleFlyerUpdate = (updatedFlyer) => {
    setFlyers(prevState => {
      const flyerIndex = prevState.findIndex(flyer => flyer.id === updatedFlyer.id);

      if (flyerIndex >= 0) {
        const updatedProducts = [...prevState];
        updatedProducts[flyerIndex] = updatedFlyer;
        return updatedProducts;
      } else {
        return [...prevState, updatedFlyer];
      }
    });
  }

  const handleCreateFlyerForm = (e) => {
    setIsEdit(false)
    setIsDelete(false)

    setTimeout(() => {
      setIsModalOpen(true)
    }, 200)
  };

  const handleUpdateFlyerForm = (e, flyer) => {
    setIsEdit(true)
    setIsDelete(false)
    setFlyer( { ...flyer })

    setTimeout(() => {
      setIsModalOpen(true)
    }, 200)
  }

  const handleDeleteFlyer = (e, flyer) => {
    setIsEdit(false)
    setIsDelete(true)
    setFlyer({...flyer})

    setTimeout(() => {
      setIsModalOpen(true)
    }, 200)
  }

  return (
    <div>
      <HeaderAd/>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>
      <div className="contGralFliyer">
        <FlashMessage flashMessage={flashMessage} setFlashMessage={setFlashMessage}></FlashMessage>

        <div className={"row mb-2"}>
          <div className='col-12'>
            <Button
              onClick={ handleRegresar }
              sx={ { border: '1px solid black', borderRadius: '15px', color: '#000' } }
              variant="outlined"
              startIcon={ <ArrowBackIosNewIcon/> }>
              Regresar
            </Button>
          </div>
        </div>

        <div className={ "row m-2" }>
          <div className='col-md-3 col-sm-12 d-flex justify-content-start align-items-center'>
            <Button
              variant="outlined"
              endIcon={ <AddIcon sx={ { color: '#20c997' } }/> }
              sx={{ border: '1px solid black', borderRadius: '15px', color: '#000', height: 'fit-content' }}
              onClick = { handleCreateFlyerForm }
            >
            Agregar Nuevo
            </Button>
          </div>
          <div className={ "col-md-9 col-sm-12" }>
            <FlyerSearchBar
              loading={loading}
              setFetchData={ setFetchData }
              searchParameters={ searchParameters }
              setSearchParameters={ setSearchParameters }
              pageParameters={ pageParameters }
              setPageParameters={ setPageParameters }
            />
          </div>
        </div>

        <FlyerDataTable
          loading={ loading }
          setLoading={ setLoading }
          fetchData={ fetchData }
          setFetchData={ setFetchData }
          flashMessage={ handleSetMessage }
          error={ error }
          setError={ setError }
          flyers={ flyers }
          setFlyers={ setFlyers }
          pageParameters={ pageParameters }
          setPageParameters={ setPageParameters }
          searchParameters={ searchParameters }
          setSearchParameters={ setSearchParameters }
          onFlyerUpdate={handleFlyerUpdate}
          handleUpdateFlyerForm={ handleUpdateFlyerForm }
          handleDeleteFlyer={handleDeleteFlyer}
        />

        <FlyerForm
          flyer={flyer}
          setFlyer={setFlyer}
          setLoading={setLoading}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          setFetchData={ setFetchData }
          onFlyerUpdate={handleFlyerUpdate}
          handleSetMessage={handleSetMessage}
        ></FlyerForm>
      </div>
    </div>
  );
}