import React from "react";
import './contactLink.css';
import {useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';

const ContacLink = () =>{
    const l = useContext(langContext);

    const idioma = ()=>{
        if(l.lang==='es'){
           return langajes.spanish
        }else if(l.lang==='en'){
            return langajes.english
        }
    }


    return(
        <div className="row contactLayout">
            <div className="col-md-12 col-lg-6 contactLinkContent">
             
                <div >
                    <p className="contactLinkText">{idioma().contactoh.textoContacto}</p>
                
                </div>
                <div className="linkContainer">
                    <a href="/contacto"><p className="contactLink">{idioma().header.navItem5}</p></a>
                </div>
            </div>
            
        </div>
    );
}

export default ContacLink;