import React, { useEffect, useState } from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IOSSwitch, StyledTableCell, StyledTableRow } from "../../../commons/StyledComponents";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditNoteIcon from "@mui/icons-material/EditNote";
import TableContainer from "@mui/material/TableContainer";
import { categoryService } from "../../../../_services/category.services";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";

export const ProductDataTable = ({ handleOpenModalEdit, flashMessage, loading, setLoading, pageParameters, setPageParameters, fetchProductData, setFetchProductData, searchParameters, setSearchParameters, products, setProducts, categories, onProductUpdate }) => {
  const { page, size, orderBy } = pageParameters;
  const [totalPages, setTotalPages] = useState(0);
  const [totalProds, setTotalProds] = useState(0);

  const dataColumns = [
    { field:"sku" },
    { field:"description" },
    { field:"category_id" },
    { field:"product_line" },
    { field:"sub_category" },
    { field:"status" },
    { field:"id" },
  ];

  useEffect( () => {
    if (fetchProductData) {
      fetchProducts().finally(() => {
        setLoading(false);
        setFetchProductData(false);
      });
    }
  }, [fetchProductData] );

  const fetchProducts = async () => {
    try {
      setLoading(true);

      await categoryService.adminProductList(pageParameters, searchParameters).then((response) => {
        setProducts(response?.data);
        setTotalProds(response?.totalRecords);
        setTotalPages(response?.totalPages)
      });
    } catch (error) {
      flashMessage(error, "error")
    }
  }

  const getCategoryName = (categoryId) => {
    const categoryIndex = categories.findIndex(category => category.id === categoryId)
    return categoryIndex >= 0 ? categories[categoryIndex].name : null;
  }

  const handleSort = (event, field) => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      orderBy: {
        field: field,
        direction: orderBy.field === field && orderBy.direction === 'asc' ? 'desc' : 'asc'
      },
    }))

    setFetchProductData(true)
  }

  const handleChangeStatus = async (productId, status) => {
    const body =  {
      "status": !status
    }

    try {
      await categoryService.updateProduct(productId, body).then((response) => {
        if (typeof response?.success !== 'undefined' && response.success) {
          onProductUpdate(response.data)
          flashMessage(response.message, "success");
        }
      });
    } catch (error) {
      flashMessage(error, "error");
    }
  }

  const handleChangePage = (event, newPage) => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: newPage
    }))

    setFetchProductData(true)
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true)
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: 0,
      size: event.target.value
    }));

    setFetchProductData(true)
  };

  return (
    <div className={ "col-12" }>
      <TableContainer component={ Paper } style={ { height: "75vh" } }>
        <Table sx={ { minWidth: 700 } } aria-label="products-table" stickyHeader>
          <TableHead sx={ { height: '45px' } }>
            <TableRow>
              <StyledTableCell align='center'>
                <TableSortLabel active={ dataColumns[6].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[6].field ) }>
                  ID
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <TableSortLabel active={ dataColumns[0].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[0].field ) }>
                  SKU
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                <TableSortLabel active={ dataColumns[1].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[1].field ) }>
                  DESCRIPCIÓN
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                <TableSortLabel active={ dataColumns[2].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[2].field ) }>
                  CATEGORÍA
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                <TableSortLabel active={ dataColumns[3].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[3].field ) }>
                  LÍNEA
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                <TableSortLabel active={ dataColumns[4].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[4].field ) }>
                  SUB-CATEGORÍA
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                IMAGEN
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                <TableSortLabel active={ dataColumns[5].field === orderBy.field } direction={ orderBy.direction }
                                onClick={ (e) => handleSort( e, dataColumns[5].field ) }>
                  ESTATUS
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell align={ "center" }>
                EDITAR
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              products.map(
                (product) => (
                  <StyledTableRow key={ product.id }>
                    <StyledTableCell align='center'>{ product.id }</StyledTableCell>
                    <StyledTableCell align='center'>{ product.sku }</StyledTableCell>
                    <StyledTableCell align='center'>{ product.description }</StyledTableCell>
                    <StyledTableCell align='center'>{ getCategoryName( product.category_id ) }</StyledTableCell>
                    <StyledTableCell align='center'>{ product.product_line }</StyledTableCell>
                    <StyledTableCell align='center'>{ product.sub_category }</StyledTableCell>
                    <StyledTableCell align='center' style={ {
                      overflow: 'hidden',
                      width: '90px',
                      height: '90px',
                      alignItems: 'center',
                      justifyContent: 'center'
                    } }>
                      <img src={ product.cover?.file_path } alt="" style={ { width: '100%', padding: '8px' } }/>
                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <FormGroup>
                        <FormControlLabel
                          checked={ !!product.status }
                          onClick={ () => handleChangeStatus( product.id, product.status ) }
                          sx={ { marginRight: 0, marginLeft: 0, justifyContent: 'center' } }
                          control={ <IOSSwitch sx={ { m: .5 } }/> }
                          label={ "" }/>
                      </FormGroup>
                    </StyledTableCell>
                    <StyledTableCell align={ "center" }>
                      <Button onClick={ (e) => handleOpenModalEdit( e, product ) }>
                        <EditNoteIcon  sx={ { color: '#5fB4F9', fontSize: "3em" } }/>
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                )
              )
            }
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="pagAdminPrdos"
        rowsPerPageOptions={ [30, 60, 240] }
        component="div"
        count={ totalProds }
        rowsPerPage={ size }
        page={ page }
        onPageChange={ handleChangePage }
        onRowsPerPageChange={ handleChangeRowsPerPage }
        showFirstButton
        showLastButton
      />
    </div>
  )
}