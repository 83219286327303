

const spanish = {
    header:{
        navItem1: "INICIO",
        navItem2: "PRODUCTOS",
        navItem3: "FLYER MENSUAL",
        navItem4: "ÚNETE AL EQUIPO",
        navItem5: "CONTACTO"
    },
    footer:{
        horario: 'Horario',
        dias: 'Lunes - Viernes:',
        findsem: 'Sábado:',
        redes: 'Síguenos:'
    },
    nosotros:{
        titulo: "Productos Bredy USA, LLC.",
        tituloNos:'Nosotros',
        textParag1: "En 1995 iniciamos la aventura exportadora entre los estados de Nuevo León en México y Texas en los Estados Unidos, consolidándonos en 2010 como Productos Bredy USA, logrando así fortalecer los lazos comerciales entre las dos naciones.",
        textParag2: "Productos Bredy USA importa y vende productos a varios estados como Texas, Mississipi, Alabama, Carolina’s, Louisiana, Arkansas y Florida.",
        textParag3: "Hacemos visita y servicio a nuestros clientes con una base de 5,000 productos de las más reconocidas marcas de diferentes países."
    },
    misionVision:{
        tituloMision: "Misión",
        textoMision: "Brindar el  mejor servicio a nuestros clientes , promoviendo las mejores marcas y productos del mercado hispano y americano manteniendo relaciones leales, honestas y productivas  enfocadas en un ganar-ganar con nuestros clientes y proveedores.",
        tituloVision: "Visión",
        textoVision: "Ser la mejor opción de compra para nuestros clientes  a través del mejor servicio  desarrollando un modelo de negocio sustentable de la mano de nuestros socios comerciales.",
        urlImagen: "misvisImg.png"
    },
    delivery:{
        urlImgDesk: 'deliveryImage.png',
        imgeMob1: 'tenemosMob.png',
        imgeMob2: 'rutaMob.png',
        imgeMob3: 'atencionMob.png'
    },
    contactoh:{
        textoContacto:'"EL TRABAJO DIARIO NOS HA GUIADO HACIA EL CAMINO DE ÉXITO, HEMOS CRECIDO DE LA MANO DE NUESTROS CLIENTES"',
    },
    productos:{
        pag: 'Página',
        de: 'de',
        ant: 'Anterior',
        sig: "Siguiente",
        ordenar:  'Ordenar por:',
        nombreProd: 'Nombre',
        placeholder: "Buscar por Nombre o SKU"
    },
    flyer:{
        tituloFyer:'Consulta Nuestro Flyer Mensual'
    },
    unete:{
        tituloUnete: 'Únete a nuestro equipo',
        textoUnete: 'Grupo Bredy, lleva mas de 20 años operando y buscando ser grandes pilares en la industria, para satisfacer las necesidades de nuestros clientes y del consumidor final. Llevando a su casa, marcas importantes de diferentes partes del mundo. Queremos que formes parte de esta gran empresa.',
        linea1:'Envíanos tu CV y ',
        linea2:'nosotros nos',
        linea3:'comunicaremos contigo',
        linea4:'¡Nos enctantaría',
        linea5:'conocerte!',
        formulario1: 'Nombre:*',
        formulario2: 'Teléfono:*',
        formulario3: 'Mensaje:*',
        formulario4: 'Adjuntar CV',
        boton: 'Enviar'
    },
    contacto:{
        contactoTitulo: 'Contacto',
        formularioAs: 'Asunto',
        suctitulo1:'VENTAS MAYOREO DE ARTESANÍA MEXICANA:',
        suctitulo2:'CASH & CARRY DE ARTESANÍA MEXICANA:',
        suctitulo3:'VENTAS TIENDAS, SUPERMERCADOS Y MAYOREO:',
        checkPol: "He leído y acepto las",
        checklink: "Políticas de privacidad",
        sendResp: "Mensaje enviado correctamente",
    }
}

const english = {
    header:{
        navItem1: "HOME",
        navItem2: "PRODUCTS",
        navItem3: "MONTLY FLYER",
        navItem4: "CAREERS",
        navItem5: "CONTACT"
    },
    footer:{
        horario: 'Office hours',
        dias: 'Monday - Friday:',
        findsem: 'Saturday: ',
        redes: 'Follow us:'
    },
    nosotros:{
        titulo: "Productos Bredy USA, LLC.",
        tituloNos:'About Us',
        textParag1: "In 1995 we began the export adventure between the states of Nuevo Leon in Mexico and Texas in the United States, consolidating in 2010 as Bredy Products USA, thus strengthening trade ties between the two nations.",
        textParag2: "Productos Bredy USA imports and sells products to several states such as Texas, Mississippi, Alabama, Carolina's, Louisiana, Arkansas and Florida.",
        textParag3: "We visit and service our customers with a base of 5,000 products of the most recognized brands from different",
    },
    misionVision:{
        tituloMision: "Mission",
        textoMision: "To provide the best service to our customers, promoting the best brands and products in the Hispanic and American market while maintaining loyal, honest and productive relationships focused on a win-win with our customers and suppliers.",
        tituloVision: "Vision",
        textoVision: "To be the best purchasing option for our customers through the best service by developing a sustainable business model hand in hand with our business partners.",
        urlImagen: "misvisImgIng.png"
    },
    delivery:{
        urlImgDesk: 'deliveryImageIng.png',
        imgeMob1: 'tenemosMobIng.png',
        imgeMob2: 'rutaMobIng.png',
        imgeMob3: 'atencionMobIng.png'
    },
    contactoh:{
        textoContacto:'"DAILY WORK HAS GUIDED US TO THE PATH OF SUCCESS, WE HAVE GROWN HAND IN HAND WITH OUR CUSTOMERS"',
    },
    productos:{
        pag: 'Page',
        de: 'of',
        ant: 'Previous',
        sig: "Next",
        ordenar:  'Order by:',
        nombreProd: 'Name',
        placeholder: "Search by Name or SKU"
    },
    flyer:{
        tituloFyer:'Check out our monthly flyer'
    },
    unete:{
        tituloUnete: 'Join our team',
        textoUnete: 'Grupo Bredy, has been operating for more than 20 years and seeking to be great pillars in the industry, to satisfy the needs of our customers and the final consumer. Bringing to your home, important brands from different parts of the world. We want you to be part of this great company.',
        linea1:'Send us your CV and ',
        linea2:'we will',
        linea3:'contact you.',
        linea4:'We would love to',
        linea5:'meet you!',
        formulario1: 'Name:*',
        formulario2: 'Phone:*',
        formulario3: 'Message',
        formulario4: 'Add CV',
        boton: 'Send'
    },
    contacto:{
        contactoTitulo: 'Contact',
        formularioAs: 'Subject:',
        suctitulo1:'MEXICAN HANDICRAFT WHOLESALE:',
        suctitulo2:'MEXICAN HANDICRAFT CASH & CARRY:',
        suctitulo3:'RETAIL AND SUPERMARKET SALES:',
        checkPol: "I have read and accept the",
        checklink: "privacy policies",
        sendResp: "Message sent successfully"
    }

    
}

export const langajes = {
    spanish,
    english
};

