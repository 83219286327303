
import Footer from './commons/Footer';
import React, {useEffect, useState, useContext} from 'react';
import './products.css';
import { categoryService } from '../_services/category.services';
import { langajes } from '../_helpers/languajes';
import { langContext } from '../App';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SearchBar from "material-ui-search-bar";
import Header from './commons/Header';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';




export const Products = () => {
const [titProds, setTitProds] = useState('Abarrotes');
const [catId, setCatId] = useState(1);
const [pageNumber, setPageNumber] = useState(1);
const [oB, setOB] = useState({ "field":"description ", "direction": "ASC" });
const [currentPage, setCurrentPage] = useState([]);
const [catArray, setCatArray] = useState([]);
const [totalPages, setTotalPages] = useState(0);
const [dataProducts, setDataProducts] = useState([]);
const [catIndex, setCatIndex] = useState(0);
const [search, setSearch] = useState();
const [searchVal, setSearchVal] = useState();
const l = useContext(langContext);
const [open, setOpen] = useState(false);

const idioma = ()=>{
    if(l.lang==='es'){
       return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
}

const getCategories = async (language) => {
    //setSearchVal();
    const categories = await categoryService.getCategories(language);
    setCatArray(categories.data);
    setTitProds(categories.data[catIndex].name);
}

useEffect(()=>{
    getCategories(l.lang);
},[l.lang]);

useEffect(()=>{
    getPrords(catId,pageNumber,searchVal,oB,l.lang);
},[catId,pageNumber,searchVal,oB,l.lang]);



const getPrords = async (cId,pNumber,srch,oBy,language) =>{
    setOpen(true);
    //setSearchVal();
    const response =  await categoryService.getProducts(cId,pNumber,srch,oBy,language);
    setOpen(false);
    setTotalPages(response.totalPages);
    setCurrentPage(response.currentPage)
    setDataProducts(response.data);
} 


const handleSetTitle = (newTitle, idCat,index) => {
    setTitProds(newTitle);
    setCatId(idCat);
    setPageNumber(1);
    setCatIndex(index);
    setSearchVal();
}

const handleChange = (e) =>{
    const catSelected = e.target.value-1;
    setTitProds(catArray[catSelected].name);
    setCatId(catArray[catSelected].id);
    setPageNumber(1);
}



const handleChangeOrdenar = (e) =>{
    const order=[
        {field:"description", direction: "ASC" },
        {field:"description", direction: "DESC" },
        {field:"sku", direction: "ASC" },
        {field:"sku", direction: "DESC" }
    ];
    const orderBy = e.target.value-1;
    setOB(order[orderBy]);

}

const handleChanchePag = (event, value) =>{
    setPageNumber(value);
}

const handleChangeSearch = (val) => {
    setSearch(val)
}

const handleSearch = () => {
    setSearchVal(search);
    setTitProds(search);
    setPageNumber(1);
}


const handleSearchKD = (e) => {
    if(e.code === 'Enter'){
        setSearchVal(search);
        setTitProds(search);
        setPageNumber(1);
    }
}


  return (

    <div >
        <Header/>
        <div className='row containerPlp'>
        <div className='col-12'>
            <div className='row'>
                <div className='col-3'></div>
                <div className='d-none d-lg-block col-md-9'><h1 className='titPLP'>{titProds}</h1></div>
            </div>
            <div className='row d-flex hbuscador' >
                <div className='col-3'>
                
                </div>
                <div className='d-none d-lg-block col-md-5'>
                    <SearchBar
                        value={searchVal}
                        className='searchBarSt'
                        onChange={(newValue) => handleChangeSearch(newValue)}
                        onRequestSearch={() => handleSearch}
                        placeholder={idioma().productos.placeholder}
                        onKeyDown={(e)=>handleSearchKD(e)}
                    />
                </div>
                <div className='d-none d-lg-block col-md-4'>
                <Box sx={{ minWidth: 120}}>
                        <FormControl fullWidth>
                            <InputLabel className='inputLabel' variant="standard" htmlFor="uncontrolled-native">
                                {idioma().productos.ordenar}
                            </InputLabel>
                            <NativeSelect
                                className='selectProds'
                                defaultValue={1}
                                inputProps={{
                                    name: 'orderBy',
                                    id: 'uncontrolled-native',
                                }}
                                onChange={(e)=>handleChangeOrdenar(e)}
                            >
                            <option value={1}>{idioma().productos.nombreProd} A-Z</option>
                            <option value={2}>{idioma().productos.nombreProd} Z-A</option>
                            <option value={3}>Sku ASC</option>
                            <option value={4}>Sku DESC</option>
                            </NativeSelect>
                        </FormControl>
                        </Box>
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-6 d-sm-block d-md-none'>
                    <select name='selectCategories' className='selectMob'
                            onChange={(e)=> handleChange(e)}
                            >
                        {catArray.map((cat)=>(
                            <option name={cat.name} value={cat.id}>{cat.name}</option>             
                        ))}
                        
                    </select>
                </div>
                <div className='col-sm-12 d-sm-block d-md-none'>
                    <SearchBar
                            value={searchVal}
                            className='searchBarSt'
                            onChange={(newValue) => handleChangeSearch(newValue)}
                            onRequestSearch={() => handleSearch}
                            placeholder={idioma().productos.placeholder}
                            onKeyDown={(e)=>handleSearchKD(e)}
                        />     
                </div>
                <div className='d-none d-md-block col-md-3 d-flex justify-content-center'>
                    <ul className='ulPlp'>
                        {catArray.map((cat,index)=>(
                            <li className='liCat' key={cat.id} onClick={()=>handleSetTitle(cat.name, cat.id,index)}>{cat.name}</li>                
                        ))}
                    </ul>
                </div>
                <div className='col-sm-12 col-md-9'>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                    <div className='row d-flex justify-content-center'>
                        {dataProducts.map((prod)=>(
                            <div className='col-6 col-md-3 itemProd'>
                                <img className='imgProds' src={prod.cover?.file_path ? prod.cover.file_path : 'https://api.bredy.ditecsis.com/storage/no-image.jpg'} alt={""}/>
                                <p className='descProd'>{prod.description}</p>
                                <p className='descProd'>SKU: {prod.sku}</p>
                            </div>

                        ))}
                    </div>
                </div>
                <div className='row rowpags'>
                    <div className='d-none d-md-block col-md-3'>
               </div> 

                    <div className='col-sm-12 col-md-9 d-line d-flex justify-content-center contPagination'>
                        <div className='row pagination'>
                            <Stack className='paginationSt' spacing={2}>
                                <Pagination count={totalPages} onChange={handleChanchePag} />
                            </Stack>
                        </div>
                    </div>   
                </div>
                
            </div>
           
        </div>
        </div>
        <Footer/>
    </div>
  )
}
