import React from "react";
import './footer.css'
import {NavLink} from 'react-router-dom';
import { useContext} from "react";
import { langajes } from "../../_helpers/languajes";
import { langContext } from "../../App";


const Footer = () =>{

    const l = useContext(langContext);

    const idioma = ()=>{
         if(l.lang==='es'){
           return langajes.spanish
        }else if(l.lang==='en'){
            return langajes.english
        }
    }


    return(
       <footer className="row">
        <div  className="row footerContainer">
            <div className="col-md-4 col-lg-3 col1">
                <h1>Houston</h1>
                <p>7601 North Loop,</p>
                <p>Houston, TX 77028</p>
                <p><a className="telFooter" href="mailto:hola@productosbredy.com">hola@productosbredy.com</a></p>
            
            </div>
            <div className="col-md-4 col-lg-3 col2">
                <h1>{idioma().footer.horario}</h1>
                <p>{idioma().footer.dias}</p>
                <p>8am – 6pm</p>
                <p>{idioma().footer.findsem} 9am - 2pm</p>
            </div>
            <div className="col-md-3 col-lg-6 col3">
                <NavLink  to="/">
                    <img className="logofoo" src="logoBredy.png" alt={""}/>
                </NavLink>
            </div>
        </div>
        <div className="row">
            <div className="follow col-12">
                <p className="followText">{idioma().footer.redes}</p>
                <a target="_blank" href="https://www.facebook.com/BredyDistributors"><img src="facebook.png" alt={""}/></a>
                <a target="_blank" href="https://www.instagram.com/bredydistributors/?hl=en"><img src="IG.png" alt={""}/></a>
                <a target="_blank" href="https://www.linkedin.com/company/productos-bredy-usa-llc/about/"><img src="linkedin.png" alt={""}/></a>
                <a target="_blank" href="https://api.whatsapp.com/send/?phone=%2B18328851028&text&type=phone_number&app_absent=0"><img src="whats.png" alt={""}/></a>
            </div>
        </div>
        
        <div className="brandBredy">
            <p>Productos Bredy USA, LLC. @2024</p>
        </div>
       </footer>
    );
};

export default Footer;