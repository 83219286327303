import React from "react";
import './us.css';
import {useContext} from "react";
import { langajes } from '../../_helpers/languajes';
import { langContext } from '../../App';


const MisionVision = () =>{

const l = useContext(langContext);

const idioma = ()=>{
    if(l.lang==='es'){
       return langajes.spanish
    }else if(l.lang==='en'){
        return langajes.english
    }
}


    return (
        <div className="usLayout">
            <div className="row  usContent">
                <div  className="col-md-12 col-lg-6 usImageMisvis">
                    <img className="misvis" src={idioma().misionVision.urlImagen} alt={""}/>
                </div>
                <div className="col-md-12 col-lg-6 misiVisiText">
                    <div className="row contentText justify-content-center align-items-center">
                        <div className=" sMision">
                            <h1>{idioma().misionVision.tituloMision}</h1>
                            <h3>{idioma().misionVision.textoMision}</h3>
                        </div>

                        <div className="col sVision">
                        <h1>{idioma().misionVision.tituloVision}</h1>
                        <h3>{idioma().misionVision.textoVision}
                        </h3>
                    </div>
                        
                    </div>
                    
                    

                    
                </div>
            </div>
            
        </div>
    );
}

export default MisionVision;