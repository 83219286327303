import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, Box, TextField, IconButton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

export const ProductSearchBar = ({ loading, categories, pageParameters, setPageParameters, searchParameters, setSearchParameters, setFetchProductData }) => {
  const { search, status, category } = searchParameters;

  const handleProductSearch = (e) => {
    if (e.key === 'Enter') {
      setFetchProductData(true)
    }

    if (e.key === 'Escape') {
      onSearchClear()
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target

    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: 0
    }))

    setSearchParameters((previousArgs) => ({
      ...previousArgs,
      [name]: value
    }))

    if (name !== 'search') {
      setFetchProductData(true)
    }
  };

  const onSearchClear = () => {
    setPageParameters((previousArgs) => ({
      ...previousArgs,
      page: 0
    }))

    setSearchParameters((previousArgs) => ({
      ...previousArgs,
      search: ""
    }))

    setFetchProductData(true)
  };

  const handleOnSearch = () => {
    setFetchProductData(true)
  }

  return (
    <div className="row">
      <div className={"col-md-8 col-sm-12"}>
        <Box
          display="flex"
          alignItems="center"
          sx={{ boxShadow: "0px 2px 1px -3px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)", backgroundColor: "rgb(23, 107, 105)", borderRadius: 4 }}
        >
          <FormControl  sx={{ width: 180 }}>
            <InputLabel id="selectCategoryLabel" sx={{ color: "white !important" }}>Categorías</InputLabel>
            <Select
              labelId={"selectCategoryLabel"}
              id={ "category-select" }
              value={ category }
              name={ "category" }
              onChange={ onChange }
              sx={{
                height: "55px",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                color: "white"
              }}
              variant={"filled"}
            >
              <MenuItem key={0} value={0}>TODAS</MenuItem>
              {
                categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>{ category.name }</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <TextField
            fullWidth
            disabled={ loading }
            value={ search }
            name={"search"}
            onChange={ onChange }
            onKeyDown={ handleProductSearch }
            placeholder="Buscar..."
            variant="outlined"
            sx={{
              flex: 1,
              backgroundColor: '#fff',
              '& .MuiOutlinedInput-root': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              },
            }}
          />
          {
            !search ? (
              <IconButton sx={{ color: "white" }} onClick={ handleOnSearch }>
                <SearchIcon />
              </IconButton>
            ) : (
              <IconButton sx={{ color: "white" }} onClick={ onSearchClear }>
                <ClearIcon />
              </IconButton>
            )
          }
        </Box>
      </div>
      <div className={"col-md-4 col-sm-12 d-flex justify-content-end align-items-center"}>
        <FormControl sx={{ width: 160 }}>
          <InputLabel id="selectStatusLabel" sx={{color: "black !important" }}>Estatus</InputLabel>
          <Select
            labelId={"selectStatusLabel"}
            value={ status }
            name={ "status" }
            onChange={ onChange }
            sx={{
              height: "55px",
            }}
            variant={"filled"}
          >
            <MenuItem key={ 1 } value={ 1 } className={"d-flex align-items-center"}>
              Activo
            </MenuItem>
            <MenuItem key={ 0 } value={ 0 }>
              Inactivo
            </MenuItem>
            <MenuItem key={ 2 } value={ 2 }>
              Todos
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}