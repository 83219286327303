import React, {useEffect} from 'react'
import Avatar from '@mui/material/Avatar';
import {NavLink} from 'react-router-dom';
import { useState, useContext} from "react";
import {useNavigate} from 'react-router-dom';
import { langContext } from '../App';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './headerAd.css'
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import ListItemText from "@mui/material/ListItemText";
import {categoryService} from "../_services/category.services";

export const HeaderAd = () => {
const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const [usLo, setUsLo] = useState();
const navigate = useNavigate();
const token = localStorage.getItem('access_token');

const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};

useEffect(() => {

    if(token === undefined || token === null){
        navigate('/login');
    }
}, []);

const handleClose = async () => {
    setAnchorEl(null);
};

const handleLogout = async () => {
    try{
        const response = await categoryService.logOut(token);
        navigate('/login');
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');
    }catch (error){
        console.log(error);
        if(error === "Unauthenticated."){
            navigate('/login');
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
        }
    }
}

const t = useContext(langContext);


const getDataUs = () =>{

    const dataUsr = JSON.parse(localStorage.getItem('user'));
    if(dataUsr){
        setUsLo(`${dataUsr.first_name} ${dataUsr.last_name}`)
    }else{
        navigate('/login')
    }
}

useEffect(()=>{
    getDataUs();
},[])

  return (
      <div className={`row`}>
          <div className="col-4">
              <NavLink to="/">
                  <img className="imgH" width="150px" src="logoBredy.png" alt={""}/>
              </NavLink>
          </div>
          <div className='col-4 colTitAd'>
              <h1>{t.titPlaceAd}</h1>
          </div>

          <div className="col-4 avatNom">
              <div>
                  <Button
                      id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                  >
                      <div className='btnSesion'>
                          <div>
                              <Avatar src='user.svg'/>
                          </div>
                          <p className='txtUsr'>{usLo}</p>
                      </div>
                  </Button>
                  <Menu
                      calssName='menuItemC'
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      MenuListProps={{
                          'aria-labelledby': 'basic-button',
                      }}
                  >
                      <MenuItem className='menuItemSty' onClick={handleLogout} onClose={handleClose}>
                          <ListItemIcon>
                              <LogoutIcon sx={{colo: '#000'}} />
                          </ListItemIcon>
                          <ListItemText>
                              Cerrar sesión
                          </ListItemText>
                      </MenuItem>
                  </Menu>
              </div>

          </div>
      </div>
  )
}
