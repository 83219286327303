import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
  import { DropzoneArea } from "mui-file-dropzone";
import { createStyles, makeStyles } from "@material-ui/core";
import { deleteFlyer, uploadFlyerFile } from "../../../../../_services/flyer.services";

const style = {
  position: 'absolute',
  top: '35vh',
  left: '50vw',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  padding: 0,
  width: '75vw',
  maxWidth: '1200px',
  paddingBottom: '50px',
};

export const FlyerForm = (
  {
    flyer,
    setFlyer,
    setLoading,
    isOpen,
    setIsOpen,
    isEdit,
    setIsEdit,
    isDelete,
    setIsDelete,
    setFetchData,
    onFlyerUpdate,
    handleSetMessage
  }) => {
  const [file, setFile] = useState(undefined);

  const useStyles = makeStyles(theme => createStyles({
    previewChip: {
      minWidth: 160,
      maxWidth: 210
    }
  }));

  const classes = useStyles();

  const handleOnClose = () => {
    setIsOpen(false)
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault()

    const validForm = typeof file !== 'undefined';
    if (validForm) {
      const id = isEdit ? flyer.id : null;

      try {
        setLoading(true)
        handleSetMessage("Cargando...", "warning", 8000)

        setIsOpen(false)
        await uploadFlyerFile(id, file).then((response) => {
          if (response.status === 200) {
            onFlyerUpdate(response.data);
            handleSetMessage(response.message, 'success');
          }
        })
      } catch (error) {
        handleSetMessage(error, "error");
      } finally {
        setLoading(false)
      }
    } else {
      handleSetMessage("No hay ningún archivo seleccionado", "error", 3000)
    }
  };

  const handleOnDelete = async () => {
    await deleteFlyer(flyer.id).then((response) => {
      if (response.status === 200) {
        handleSetMessage(response.message, 'success');
        setIsOpen(false)
        setTimeout(() => {
        setFetchData(true);
        },800)
      }
    })
  }

  const isEditOrDelete = (isEdit || isDelete) && flyer.thumbnail_url !== null;

  return (
    <Modal
      open={ isOpen }
      onClose={ handleOnClose }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ style }>
        <div className='row'>
          {
            isEdit ? (
              <h1 className='titModalEdit'>Remplazar Flyer</h1>
            ) : (
              isDelete ? (
                <h1 className='titModalEdit'>Eliminar Flyer</h1>
              ) : (
                <h1 className='titModalEdit'>Agregar Flyer</h1>
              )
            )
          }
        </div>

        <div className={ "row" }>
          {
            isEditOrDelete && (
              <div className={ "d-flex justify-content-center" }>
                <img src={ flyer.thumbnail_url } alt="" style={ { padding: '8px', width: '100%', maxWidth: '220px' } }/>
              </div>
            )
          }
          <div className={ "col-12" }>
          {
              !isDelete ? (
                <form onSubmit={ handleOnSubmit }>
                  <div className={ "p-2" }>
                    <DropzoneArea
                      filesLimit={ 1 }
                      acceptedFiles={ ['application/pdf'] }
                      dropzoneText={ "Arrastra y suelta el archivo o da clic aquí." }
                      onChange={ (files) => setFile( files[0] ) }
                      showPreviews={ true }
                      showPreviewsInDropzone={ false }
                      useChipsForPreview
                      previewGridProps={ { container: { spacing: 1, direction: 'row' } } }
                      previewChipProps={ { classes: { root: classes.previewChip } } }
                      previewText="Archivo seleccionado"
                      maxFileSize={268435456}
                    />
                  </div>

                  <div className={ "d-flex justify-content-around" } style={ { textAlign: 'right' } }>
                    <button className={ "btn btn-outline-danger" } style={ { width: 133 } } onClick={ handleOnClose }>
                      Regresar
                    </button>

                    <button className={ "btn btn-outline-success" } style={ { width: 133 } }>
                      Enviar
                    </button>
                  </div>
                </form>
              ) : (
                <div className={ "row" }>
                  <div className={ "col-12 text-center p-4"}>
                    Esta acción únicamente eliminará el registro local del Flyer, es necesario borrar manualmente el registro en Heyzine, continuar?
                  </div>
                  <div className={"col-12 d-flex justify-content-around"}>
                    <button className={"btn btn-outline-success"} style={{ width: 133 }} onClick={ handleOnClose }>Regresar</button>
                    <button className={"btn btn-outline-danger"} style={{ width: 133 }} onClick={ handleOnDelete }>Eliminar</button>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </Box>
    </Modal>
  )
}